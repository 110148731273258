import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserData } from '../App';

export interface ResponseScaffold<T> {
  data: T;
  success: boolean;
  message: string;
}

export type ProtectedRequest = {
  token: string;
};

type SessionRequest = {
  session: ProtectedRequest;
};

export type RequestError = AxiosError | Error;

export type Response<T> = Promise<AxiosResponse<T> | RequestError>;

export type RequestResponse<T, D> = (payload: D) => Response<T>;

// export type ShareParams = {
//   appId: string;
//   name: string;
//   scopes?: string[];
//   terms?: string;
// };

export type StartMobileExperiencePayload = ProtectedRequest;

export const startMobileExperience: RequestResponse<
  null,
  StartMobileExperiencePayload
> = async (payload) => {
  try {
    const res = await axios.post<null>('api/start', payload);
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

interface RegisterUserResponseData {
  token: string;
  userData: Omit<UserData, 'userId'> & { id: string };
}

interface RegisterParams {
  phone?: string;
  email?: string;
  firstName: string;
  lastName: string;
}

export const registerUser: RequestResponse<
  ResponseScaffold<RegisterUserResponseData>,
  RegisterParams
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<RegisterUserResponseData>>(
      'api/register',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

interface LatestScanResponseData {
  scanId?: string | null;
}
type LatestScanPayload = ProtectedRequest;

export const getLatestScan: RequestResponse<
  ResponseScaffold<LatestScanResponseData>,
  LatestScanPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<LatestScanResponseData>>(
      `api/user/scan`,
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

type OrderData = [
  {
    id: string;
    variant_id: string;
    product_id: string;
    quantity: number;
    properties: {
      Button: string;
      Fabric: string;
      Fit: string;
      Pocketing: string;
      ['Sized For']: string;
      _formcutConfiguredItemId: string;
      _formcutConfiguredItemPreview: string;
    };
  }
];

export type FindUserPayload = (
  | { session: { email: string } }
  | SessionRequest
) & { includeOrders?: boolean };

export type FindUserResponseData = {
  id?: string;
  scanId?: null | string;
  phone: null | string;
  email: null | string;
  firstName: string;
  lastName: string;
  scanDate?: null | string;
  userToken?: string;
  orders?: OrderData;
};

export const findUser: RequestResponse<
  ResponseScaffold<FindUserResponseData>,
  FindUserPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<FindUserResponseData>>(
      'api/user',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

export type SendOtpPayload = {
  phone?: string;
  email?: string;
};

export type SendOtpResponseData = Record<string, never>; // Empty object

export const sendOtp: RequestResponse<
  ResponseScaffold<SendOtpResponseData>,
  SendOtpPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<SendOtpResponseData>>(
      'api/user/send_otp',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

export type VerifyOtpPayload = {
  phone?: string;
  email?: string;
  otp: string;
};

export type VerifyOtpResponseData = {
  token: string;
};

export const verifyOtp: RequestResponse<
  ResponseScaffold<VerifyOtpResponseData>,
  VerifyOtpPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<VerifyOtpResponseData>>(
      'api/user/verify_otp',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

export type StoreOrderPayload = {
  orders: OrderData;
} & SessionRequest;

export type StoreOrderResponseData = {
  token: string;
};

export const storeOrder: RequestResponse<
  ResponseScaffold<VerifyOtpResponseData>,
  StoreOrderPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<VerifyOtpResponseData>>(
      'api/user/order_cache',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};

export type GetOrderPayload = ProtectedRequest;

type GetOrderResponseData = {
  orders: OrderData;
};

export const getOrder: RequestResponse<
  ResponseScaffold<GetOrderResponseData>,
  GetOrderPayload
> = async (payload) => {
  try {
    const res = await axios.post<ResponseScaffold<GetOrderResponseData>>(
      'api/user/order_cache/get',
      payload
    );
    return res;
  } catch (err) {
    const error = err as Error | AxiosError;
    return error;
  }
};
